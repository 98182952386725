import React from 'react';

const VideoPlayer = ({ videoId }) => {
  const videoUrls = {
    video1: 'https://www.youtube.com/embed/VIDEO_ID_1',
    video2: 'https://www.youtube.com/embed/VIDEO_ID_2',
    video3: 'https://www.youtube.com/embed/VIDEO_ID_3',
  };

  const videoUrl = videoUrls[videoId];

  if (!videoUrl) {
    return <div>Invalid video ID.</div>;
  }

  return (
    <div style={{ flex: 1 }}>
      <iframe
        width="560"
        height="315"
        src={videoUrl}
        title="Video Player"
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
