import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import LTIRedirect from './pages/lti-redirect';
import VideoSelectionPage from './pages/video-selection';
import VideoPage from './pages/video';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<VideoSelectionPage />} />
        <Route path="/video" element={<VideoPage />} />
      </Routes>
    </Router>
  );
};

export default App;
