import React, { useState, useEffect } from 'react';
import axios from 'axios';

const VideoSelection = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Sample list of videos
  const videos = [
    { id: 'video1', title: 'Introduction to Canvas' },
    { id: 'video2', title: 'Advanced LTI Integration' },
    { id: 'video3', title: 'Best Practices in Online Learning' },
  ];

  const handleEmbed = async () => {
    if (!selectedVideo) {
      alert('Please select a video.');
      return;
    }

    setIsLoading(true);

    try {
      // Prepare content items
      const contentItems = [
        {
          type: 'ltiResourceLink',
          title: selectedVideo.title,
          url: `https://api-dev.videonotebook.com/lti/launch?videoId=${selectedVideo.id}`,
          presentation: {
            documentTarget: 'iframe',
            width: 1080,
            height: 640,
          },
        },
      ];

      // Send to backend to generate Deep Linking response
      const response = await axios.post(
        'https://api-dev.videonotebook.com/lti/deep-link',
        { contentItems },
        // { withCredentials: true } // Include cookies if using sessions
      );

      const { jwt, deepLinkReturnUrl } = response.data;

      // const { jwt, deepLinkReturnUrl } = {
      //   "jwt": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkFYRHo2RUFla0Q3Y0d4VEhYenplWVZ5VUpLOExQeFBDd05lWGtEQmNtZkFYWHRuMk5lendDQ2hNQkdDNFg0a00ifQ.eyJpc3MiOiJodHRwczovL2FzdWNlLnRlc3QuaW5zdHJ1Y3R1cmUuY29tIiwiYXVkIjoiMTA2NDkwMDAwMDAwMDAwMzM2IiwiaWF0IjoxNzMxNDMwNTc4LCJleHAiOjE3MzE0MzExNzgsImh0dHBzOi8vcHVybC5pbXNnbG9iYWwub3JnL3NwZWMvbHRpL2NsYWltL21lc3NhZ2VfdHlwZSI6Ikx0aURlZXBMaW5raW5nUmVzcG9uc2UiLCJodHRwczovL3B1cmwuaW1zZ2xvYmFsLm9yZy9zcGVjL2x0aS9jbGFpbS92ZXJzaW9uIjoiMS4zLjAiLCJodHRwczovL3B1cmwuaW1zZ2xvYmFsLm9yZy9zcGVjL2x0aS9jbGFpbS9kZXBsb3ltZW50X2lkIjoiMTI2ODA6ZDI4ODJmMGQzYjUwYTZlNmM4OWJjODFjNjFiZTYzOGRlYTIzYjBhYiIsImh0dHBzOi8vcHVybC5pbXNnbG9iYWwub3JnL3NwZWMvbHRpLWRsL2NsYWltL2NvbnRlbnRfaXRlbXMiOlt7InR5cGUiOiJsdGlSZXNvdXJjZUxpbmsiLCJ0aXRsZSI6IlZpZGVvIFRpdGxlIiwidXJsIjoiaHR0cHM6Ly9hcGktZGV2LnZpZGVvbm90ZWJvb2suY29tL2x0aS9sYXVuY2g_dmlkZW9JZD12aWRlbzEifV19.3i3VFiRoXUQs-YfcOkW_Seb8dLruSShsgav3QDf-cfxHvSqwhtFS6Nihp_U2fn05m5S-GAPTtFN_tHVPgWuMlSHTJz65TeHrLNbFHPyBjlatkuFpVIh-shLZVYE7m0urfJIujLYvMbaFFOK9pBqz9bTDmxtxbIgtvftyuJCJqy_toqh892vY3efBFxaX1vwX-HM0YofMnEUr6aS6yeKgj_tHn1yb6bpNxp4e9xOQSBYA32Zew3udQBbDFet1mSnugiQnDagPIqO_w1DPoPUrV7bnRoWrFg8nCg9_CTNPdL0ZJJt4mXNW_mtrB7ot6bXoHTudmGdgncScIAtF5J_dBQ",
      //   "deepLinkReturnUrl": "https://asuce.test.instructure.com/courses/7786/deep_linking_response?data=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJub25jZSI6IjY2MDUyNDNiLWY5MDUtNDU1ZS05NTE0LTAzNjAxMjNhMTliMCIsIm1vZGFsIjp0cnVlLCJwbGFjZW1lbnQiOiJlZGl0b3JfYnV0dG9uIn0.zhVoBht8VKi5cDGVxOAZugGyH44ClU9Sawq0SRuq5qE"
      // };
      console.log('Deep Linking JWT:', jwt);
      console.log('Deep Linking Return URL:', deepLinkReturnUrl);

      // Submit the Deep Linking response to Canvas
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = deepLinkReturnUrl;

      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'JWT';
      input.value = jwt;

      form.appendChild(input);
      document.body.appendChild(form);
      console.log('Submitting form:', form);
      form.submit();
    } catch (error) {
      console.error('Error generating deep link:', error);
      alert('An error occurred while embedding the content.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h1>Select Video to Embed</h1>
      <ul>
        {videos.map((video) => (
          <li key={video.id}>
            <label>
              <input
                type="radio"
                name="video"
                value={video.id}
                onChange={() => setSelectedVideo(video)}
              />
              {video.title}
            </label>
          </li>
        ))}
      </ul>
      <button onClick={handleEmbed} disabled={!selectedVideo || isLoading}>
        {isLoading ? 'Embedding...' : 'Embed Selected Video'}
      </button>
    </div>
  );
};

export default VideoSelection;
