import React, { useState, useEffect } from 'react';

const userId = 'user1';

const NotesList = ({ videoId }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');

  useEffect(() => {
    const storedNotes = localStorage.getItem(`notes_${userId}_${videoId}`);

    if (storedNotes) {
      setNotes(JSON.parse(storedNotes));
    }
  }, [userId, videoId]);

  const saveNotes = (updatedNotes) => {
    setNotes(updatedNotes);
    localStorage.setItem(
      `notes_${userId}_${videoId}`,
      JSON.stringify(updatedNotes)
    );
  };

  const addNote = () => {
    if (newNote.trim()) {
      const note = { id: Date.now().toString(), content: newNote };
      const updatedNotes = [...notes, note];
      saveNotes(updatedNotes);
      setNewNote('');
    }
  };

  return (
    <div style={{ flex: 1 }}>
      <h2>Your Notes</h2>
      <ul>
        {notes.map((note) => (
          <li key={note.id}>{note.content}</li>
        ))}
      </ul>
      <textarea
        value={newNote}
        onChange={(e) => setNewNote(e.target.value)}
        placeholder="Write your note here..."
        rows={4}
        cols={50}
      ></textarea>
      <br />
      <button onClick={addNote} disabled={!newNote.trim()}>
        Add Note
      </button>
    </div>
  );
};

export default NotesList;
