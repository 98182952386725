import React from 'react';
import { useLocation } from 'react-router-dom';

import VideoPlayer from '../components/video-player';
import NotesList from '../components/notes-list';

const VideoPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const videoId = params.get('videoId');

  if (!videoId) {
    return <div>Missing videoId.</div>;
  }

  return (
    <div style={{ display: 'flex' }}>
      <VideoPlayer videoId={videoId} />
      <NotesList videoId={videoId} />
    </div>
  );
};

export default VideoPage;
